.nav-link {
	position: relative;
	font-size: 1.3rem !important;
    color: var(--brown-color);
}

.nav-link:hover{
	color: var(--brown-color);
}

.nav-link::before {
	content: "";
	position: absolute;
	height: 3px;
	width: 0;
	left: 0;
	bottom: 0;
	background-color: var(--brown-color);
	transition: all 0.3s ease-in-out;
}

.nav-link:hover::before {
	width: 100%;
}

.navbar {
	position: relative;
	box-shadow: 0 0 10px black;
	overflow: hidden;
	border-radius: 0 0 10px 10px;
	z-index: 10;
}

.navbar-nav {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 2vw;
}

.navbar .btn-secondary {
	background-color: var(--brown-color) !important;
	color: white !important;
	font-size: 1.3rem !important;
}

.navbar .btn-secondary:hover{
    background-color: gray !important;
}
