.Login{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
}

.LoginForm{
    min-width: min(90vw, 40rem);
    min-height: min(90vh, 40rem);
    box-shadow: 0 0 20px 5px var(--brown-color);
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: white;
}

.Login input{
    border: .1rem solid var(--brown-color);
}

.Login .btn-secondary{
    background-color: var(--brown-color);
}